<template>
  <div v-bind="getRootProps()"
       @dragenter.prevent="toggleActive"
       @dragleave.prevent="toggleActive"
       @dragover.prevent
       @drop.prevent="toggleActive"
       :class="{ 'active-dropzone': active }"
       class="dropzone"
       v-if="!this.isUploading && !isProcessing"
       id='dropzonearea'
  >
    <div class="dropzone_inside">
      <div class="inside" v-if='!isUploading'>
        <input v-bind="getInputProps()" id="dropzoneFile" class="dropzoneFile"/>
      </div>
    </div>
  </div>
  <div v-if='isUploading' class='gauge'>
    <highcharts :p="percentComplete" :h="ht"></highcharts>
  </div>
  <div v-if="isProcessing"><img width=40 src='/img/spinner.svg' />Processing video...</div>
  <p id="objUrl" style='visibility:hidden;'></p>

</template>

<script>
/* eslint-disable */
import { inject, ref } from 'vue';
import Uppy from '@uppy/core';
import Tus from '@uppy/tus';
import { useDropzone } from 'vue3-dropzone';
import MapChart from '../highcharts/solidgauge';

export default {
  name: 'DropZone',
  components: {
    highcharts: MapChart,
  },
  props: ['docid', 'n','index','height'],
  emits: ['uploadCompleted','uploadStarted'],
  setup(props,context) {
    const percentComplete = ref(0.0);
    const isUploading = ref(false);
    const isProcessing = ref(false);
    const uppy = new Uppy({debug: true, autoProceed: false});
    const cloudflareLink = inject('cloudflareLink');
    let videoLink = '';
    uppy
        .use(Tus, {
          endpoint: `https://cloudflare-streams-tus-upload.wave7.workers.dev/upload`,
          chunkSize: 150 * 1024 * 1024,
          onAfterResponse: (req, res) => {
            try {
              let smID = res.getHeader("Stream-Media-Id");
              videoLink = cloudflareLink + smID + '/iframe';
            } catch (e) {
              console.log('ErrorInfo: ', e)
            }
          },
          disableCache: true
        })
        .on('upload', () => {
          isUploading.value = true;
        })
        .on('upload-progress', (file, progress) => {
          let percent = (progress.bytesUploaded / progress.bytesTotal) * 100;
          percentComplete.value = Math.round(percent);
          console.log(percentComplete.value);
        })
        .on('upload-success', () => {
          isProcessing.value = true;
          let testInterval = setInterval(function() {
              fetch(videoLink.replace('/iframe','/metadata/playerEnhancementInfo.json'),{
                method:'GET',
                mode: 'cors'
              }).then((response) => {
                if (response.status !== 200) {
                  throw Error(JSON.stringify(response));
                }
              }).then(function() {
                clearInterval(testInterval);
                isProcessing.value = false;
                localStorage.class = "video_box";
                context.emit("uploadCompleted",1, videoLink);
              }).catch(function(error) {
                console.log("ERROR: ", error);
                console.log("waiting for video");
              });
          },3000);
        });
    let ht = ref(0);
    ht = props.height;
    console.log("Height set in cloudflare comp" + ht);

    async function uploadFile(files) {
      const file = files[0];
      //todo remove "true ||" for later use
      if (true || (file.name.includes(".mp4") || file.name.includes(".mov") || file.name.includes(".wmv") || file.name.includes(".avi") || file.name.includes(".hevc"))) {
        uppy.addFile(file);
        isUploading.value = true;
        uppy.upload().then((result) => {
          console.info('Successful uploads:', result.successful);
          if (result.failed.length > 0) {
            console.error('Errors:');
            result.failed.forEach((file) => {
              console.error(file.error);
            });
            return false;
          }
          isUploading.value = false;
          localStorage.class = "video_box";
          context.emit("uploadCompleted",1);
          return true;
        });
      } else {
        alert('Wrong file format. Video must be mp4, mov, wmv, hevc or avi format');
        localStorage.class = "video_box";
        context.emit("uploadCompleted",-1);
        return false;
      }
    }

    function onDrop(acceptFiles, rejectReasons) {
      uploadFile(acceptFiles).then((value) => {
        if (value) {
          isUploading.value = true;
        } else {
          isUploading.value = false;
          console.log(rejectReasons);
        }
      });
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });
    const active = ref(false);
    const toggleActive = () => {
      active.value = !active.value;
    };

    return {
      active,
      ht,
      percentComplete,
      isUploading,
      isProcessing,
      toggleActive,
      getRootProps,
      getInputProps,
      ...rest,
    };
  }
}
</script>

<style lang="scss" scoped>

*{
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
}
.pdf_edit {
  .dropzone_inside {
    .inside {
      height:200px;
    }
  }
}
.video_edit {
  .dropzone_inside {
    .inside {
      height:200px;
    }
  }
}
.gauge {
  border-radius: 16px;
  border: dashed 2px green;
  height: 71vh;
}
.dropzone {
  border-radius: 16px;
  box-sizing: border-box;
  transition: 0.3s ease all;
  background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%25' fill='none' rx='16' ry='16' stroke='rgba(0, 108, 62, 0.15)' stroke-width='6' stroke-dasharray='20%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: #099F450D;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  height: 87vh;
}
.dropzone::after {
  content: 'Click here or drag and drop a video file';
  background-image: url(../../../public/img/library/uploader.svg);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 96px;
  display: inline-block;
  position:absolute;
  left: calc(50% - 105px);
  font-size: 1rem;
  font-weight: 600;
  color: #787878;
  line-height: 24px;
  width: 210px;
  height: 162px;
  padding-top: 114px;
  top: calc(50% - 100px);
}
.active-dropzone {
  color: #fff;
  border-color: #fff;
  background-color: #41b883;
  label {
    background-color: #fff;
    color: #41b883;
  }
}
@media screen and (max-width: 1024px) and (min-width: 600px) and (max-height: 1366px)
and (min-height: 961px) and (orientation: portrait){
  .dropzone{
    width: calc(100% - 120px);
    margin: 0 auto;
    height: 71vh;
    position: initial;
  }
}
@media screen and (max-width: 599px) and (orientation: portrait),
screen and (max-height: 500px) and (orientation: landscape){
  .dropzone{
    width: 100%;
    height: 71vh;
  }
}
</style>
